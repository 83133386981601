import {directive as onClickaway} from 'vue-clickaway';
import {mapActions, mapMutations} from "vuex";
import _ from "lodash";

export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    type: {
      type: String,
      default: 'objectData'
    },
    value: {},
    label: {
      type: String,
      default: ''
    },
    progress: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: 'default'
    },
    itemTitle: {
      type: String,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      resetItem: false,
      page: 0,
      openSelect: false,
      selectedItem: {},
      findCountries: [],
      selectedFilters: {
        category: '',
        type: {
          name: this.$t('toDefault'),
          key: 'default'
        }
      }
    }
  },
  watch: {
    value() {
      this.changeSelect(this.value)
    },
  },
  created() {

    this.selectedItem = {}
    this.selectedItem = this.value;
  },
  computed: {},
  mounted() {
    this.$refs.search.focus()
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
    changeSelect(item) {
      setTimeout(() => {
        this.openSelect = false;
      }, 200)
      if (this.$route.query.hasOwnProperty('id'))
        this.$router.replace({name: 'catalog', query: ''})
      this.resetItem = false;
      this.selectedItem = item;
      this.$emit('input', item);
    },
    away: function () {
      this.openSelect = false
    },
    findArray(e) {
      this.$refs.input.onkeydown = (() => {
        var key = event.keyCode || event.charCode;
        if (key == 8 || key == 46) {
          this.selectedItem = {}
          this.findCountries = []
        }
      })
      if (e.length === 0) {
        this.findCountries = []
      } else if (e.length === 1) {
        setTimeout(() => {
          for (let i in this.list) {
            if (this.list[i].title.slice(0, 1) === e.toUpperCase().slice(0, 1)) {
              this.findCountries.push(this.list[i])
            }
          }
        }, 100)

      }
    },
    open() {



      this.openSelect = !this.openSelect


      setTimeout(()=>{
        this.$refs.search.focus();
      },100);

    }
  }
}
