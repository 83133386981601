import {mapActions, mapGetters, mapMutations} from "vuex";
import mainButton from '@/components/main-button/index.vue';
import mainSelect from '@/components/main-select/index.vue';
import searchSelect from '@/components/search-select-country/index.vue';
import deliveryNovaPoshta from '@/modules/order/components/delivery-nova-poshta/index.vue'
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email, sameAs} from "vuelidate/lib/validators";
//sections

export default {
    name: "order",
    mixins: [validationMixin],
    components: {
        mainButton,
        mainSelect,
        deliveryNovaPoshta,
        searchSelect
    },
    data() {
        return {
            deliveryType: null,
            selectCountry: {},
            deliveryPrice: null,
            status: 0,
            confirmation: false,
            payload: {
                delivery_method: '',
                delivery_city: '',
                delivery_department: '',
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                country_code: '',
                city: '',
                address: '',
                zip_code: '',
                comment: '',
                dresses: []
            },
            validationErrors: {},
            vueTel: {
                phone: '',
                props: {
                    maxLen: 11,
                    validCharactersOnly: true,
                    mode: "international",
                    dynamicPlaceholder: 'true',
                    defaultCountry: '',
                    autoDefaultCountry: false,
                    disabled: false,
                    required: true,
                    enabledCountryCode: false,
                    enabledFlags: true,
                    onlyCountries: [],
                    ignoredCountries: [],
                    autocomplete: "off",
                    name: "telephone",
                    inputClasses: "",
                    inputOptions: {
                        showDialCode: true,
                    },
                    disabledFormatting: true,
                    wrapperClasses: "input-row"
                }
            },
        }
    },
    validations: {
        payload: {
            first_name: {
                required,
                maxLength: maxLength(25),
                minLength: minLength(2),
            },
            last_name: {
                required,
                maxLength: maxLength(25),
                minLength: minLength(2)
            },
            phone: {
                required,
                maxLength: maxLength(18),
                minLength: minLength(7)
            },
            email: {
                email,
                maxLength: maxLength(30),
                required,
            },
            country_code: {
                required,
            },
            city: {
                required,
                maxLength: maxLength(25),
                minLength: minLength(2),
            },
            address: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(2),
            },
            zip_code: {
                required,
                maxLength: maxLength(20),
                minLength: minLength(2),
            },
            comment: {
                maxLength: maxLength(100),
                minLength: minLength(4),
            }
        },
    },
    created() {
        this.fetchDelivery().then(() => {
            this.deliveryType = this.delivery[0]
        })
        this.fetchCountry()
        if (this.isAuthenticated) {
            this.fetchUserData().then(() => {
                this.setUserData()
            })
        }
        this.fetchContent('online-store-public-offer-agreement')
    },
    computed: {
        ...mapGetters({
            products: 'basket/basket',
            delivery: 'order/delivery',
            country: 'order/country',
            isAuthenticated: 'auth/isAuthenticated',
            userData: 'profile/user',
            loader: 'order/isLoading',
            contentList: 'pages/page'
        }),
        totalSum() {
            return this.products.reduce((accamulator, currentValue) => {
                return accamulator + (currentValue.price * currentValue.selectCount)
            }, 0)
        },
        firstNameError() {
            const error = []
            if (!this.$v.payload.first_name.$dirty) {
                return error
            }
            if (!this.$v.payload.first_name.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.first_name.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.first_name.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.first_name) {
                this.validationErrors.first_name.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        lastNameError() {
            const error = []
            if (!this.$v.payload.last_name.$dirty) {
                return error
            }
            if (!this.$v.payload.last_name.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.last_name.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.last_name.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.last_name) {
                this.validationErrors.last_name.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        phoneError() {
            const error = []
            if (!this.$v.payload.phone.$dirty) {
                return error
            }
            if (!this.$v.payload.phone.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.phone.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 15))
            }
            if (!this.$v.payload.phone.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 7))
            }
            if (this.validationErrors.phone) {
                this.validationErrors.phone.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        emailError() {
            const error = []
            if (!this.$v.payload.email.$dirty) {
                return error
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('errorEmail'))
            }
            if (!this.$v.payload.email.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 30))
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        countryCodeError() {
            const error = []
            if (!this.$v.payload.country_code.$dirty) {
                return error
            }
            if (!this.$v.payload.country_code.required) {
                error.push(this.$t('errorRequired'))
            }
            if (this.validationErrors.country_code) {
                this.validationErrors.country_code.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        cityError() {
            const error = []
            if (!this.$v.payload.city.$dirty) {
                return error
            }
            if (!this.$v.payload.city.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.city.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.city.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.city) {
                this.validationErrors.city.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        addressError() {
            const error = []
            if (!this.$v.payload.address.$dirty) {
                return error
            }
            if (!this.$v.payload.address.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.address.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 50))
            }
            if (!this.$v.payload.address.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.address) {
                this.validationErrors.address.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        zipCodeError() {
            const error = []
            if (!this.$v.payload.zip_code.$dirty) {
                return error
            }
            if (!this.$v.payload.zip_code.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.zip_code.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 20))
            }
            if (!this.$v.payload.zip_code.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.zip_code) {
                this.validationErrors.zip_code.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        commentError() {
            const error = []
            if (!this.$v.payload.comment.$dirty) {
                return error
            }
            if (!this.$v.payload.comment.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 20))
            }
            if (!this.$v.payload.comment.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.comment) {
                this.validationErrors.comment.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
    },

    methods: {
        ...mapMutations({
            reduceBasket: 'basket/DECREMENT_BASKET_LIST_COUNT',
            increaseBasket: 'basket/INCREMENT_BASKET_LIST_COUNT',
            resetBasket: `basket/RESET_BASKET`,
            removeItem: 'basket/REMOVE_ITEM_FROM_BASKET'
        }),
        ...mapActions({
            fetchDelivery: 'order/GET_DELIVERY',
            fetchCountry: 'order/GET_COUNTRY',
            fetchUserData: `profile/FETCH_USER_DATA`,
            createOrder: `order/CREATE_ORDER`,
            fetchContent: 'pages/GET_PAGE'
        }),
        setUserData() {
            this.payload.first_name = this.userData.firstName
            this.payload.last_name = this.userData.lastName
            this.payload.phone = this.userData.phone
            this.payload.email = this.userData.email
            this.userData.country === null ? this.selectCountry = '' : this.selectCountry = this.userData.country
        },
        confirm(){
            this.payload.country_code = this.selectCountry.id
            this.payload.delivery_method = this.deliveryType.id
            this.$v.$touch();
            if (!this.$v.payload.$invalid) {
                this.status = 1
            }
        },
        insertData(data){
            this.payload.delivery_city = data.town
            this.payload.delivery_department = data.department
            console.log(this.payload)
        },
        changeDelivery(){
            if(this.selectCountry.id==='UA'){
               this.deliveryType = this.delivery.find(item=>{
                    return item.slug === 'nova-poshta'
                })
            }
            else{
                this.deliveryType = this.delivery.find(item=>{
                    return item.slug === 'dhl'
                })
            }
        },
        submitForm() {
            this.$v.$touch();
            this.products.forEach((el) => {
                let dress = {
                    id: el.id,
                    quantity: el.selectCount,
                    size: el.selectSizeId,
                }
                this.payload.dresses.push(dress)
            })
            if (!this.$v.payload.$invalid) {
                console.log(this.payload);
                this.createOrder(this.payload).then((result) => {
                    this.$toasted.success(this.$t('successOrder'));
                    this.resetBasket()
                    this.status = 2
                }).catch(error => {
                    if (error.status === 422) {
                        this.$toasted.error(error.data.message);
                        const errors = error.data.errors;
                        for (const i in errors) {
                            errors[i].forEach(e => {
                                this.$toasted.error(e);
                            })
                        }
                    }
                    if (error.status === 406) {
                        this.$toasted.error(error.data.message);
                        const errors = error.data.errors;
                        for (const i in errors) {
                            errors[i].forEach(e => {
                                this.$toasted.error(e);
                            })
                        }
                    }
                })
            }
        },
    }
}
