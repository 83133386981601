import autocomplete from '@/components/autocomplete/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
// import buyFlowMixin from '@/mixins/buy-flow'

export default {
    name: 'delivery-nova-poshta',


    // mixins: [buyFlowMixin],
    data() {
        return {
            delivery: {},
            typePayment: {},
            town: '',
            department: '',
            isValid: false,
            isValidTown: false,
            isValidDepartment: false,
            resultData: {
                town: '',
                department: ''
            }
        }
    },
    components: {
        autocomplete
    },
    computed: {
        ...mapGetters({
            towns: 'order/towns',
            departments: 'order/departments',
            currentLanguage: 'currentLanguage'
        }),
    },
    watch: {
        town() {
            const currentTown = this.towns.find(e => e.Description === this.town)
            // this.department = ''

            if (!currentTown) {
                return
            }

            if (this.town === currentTown.Description) {
                this.onRequestDepartments('')
            } else {
                this.setDepartments([])
            }
            this.changeAddress({town: this.town, department: this.department})

        },
        department() {
            this.checkValidData()
        }
    },

    methods: {
        ...mapActions({
            fetchTowns: 'order/NOVA_POSHTA_GET_TOWNS',
            fetchDepartments: 'order/NOVA_POSHTA_GET_DEPARTMENS',

        }),

        ...mapMutations({
            setDepartments: 'order/NOVA_POSHTA_SET_DEPARTMENS',
            changeAddress: 'order/CHANGE_ADDRESS',
        }),
        onRequestTown(val) {

            const formData = JSON.stringify({
                "modelName": "Address",
                "calledMethod": "getCities",
                "methodProperties": {
                    "FindByString": val,
                },
                "apiKey": "fabe6d1af4bb606c075d48052cd8bf83"
            })
            this.fetchTowns(formData).then(() => {
                const currentTown = this.towns.find(e => e.Description === this.town)
                // this.department = ''

                if (!currentTown) {
                    return
                }

                if (this.town === currentTown.Description) {
                    this.onRequestDepartments('')
                } else {
                    this.setDepartments([])
                }
                this.checkValidData()
            })
        },
        onRequestDepartments(val) {
            const formData = JSON.stringify({
                "modelName": "AddressGeneral",
                "calledMethod": "getWarehouses",
                "methodProperties": {
                    "CityName": this.town,
                    "FindByString": val,
                },
                "apiKey": "fabe6d1af4bb606c075d48052cd8bf83"
            })
            this.fetchDepartments(formData).then(() => {
                this.checkValidData()

            })
        },
        checkValidData() {
            const currentTown = this.towns.find(e => e.Description === this.town)
            if (currentTown) {
                this.isValidTown = this.town === currentTown.Description && this.town.length !== 0
            } else {
                this.isValidTown = false
            }

            const currentDepartment = this.departments.find(e => e.Description === this.department)
            if (currentDepartment) {
                this.isValidDepartment = this.department === currentDepartment.Description && this.department.length !== 0
            } else {
                this.isValidDepartment = false
            }

            this.isValid = this.isValidTown && this.isValidDepartment
            this.changeAddress({town: this.town, department: this.department})
            this.$emit('input', this.isValid)
        },
        pushData(department){
            this.resultData.town = this.town
            this.resultData.department = department
            this.$emit('delivery', this.resultData);
        }
    },
    created() {

        this.onRequestTown(this.town)
        this.onRequestDepartments('')
    },
}
